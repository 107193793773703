<script>
  let name = "svelte-slideshow";
  let images =[];
  let isLoading = false;
  let w;
	let h;

  import { onMount } from 'svelte';

  import Slide from "./Slide.svelte";

  import Caption from "./Caption.svelte";

  import Thumbnail from "./Thumbnail.svelte";

  isLoading = true;
  let getImages = fetch("https://www.geophysik.uni-muenchen.de/en/slideshow/album.json")
    .then(res => {
      if (!res.ok) {
        throw new Error("Failed!");
      }
      return res.json();
    })
    .then(data => {
      isLoading = false;
      images = Object.values(data);
			return images; 
    })
    .catch(err => {
      isLoading = false;
      console.log(err);
    });

  let imageShowIndex = 0;

  $: console.log(imageShowIndex)

  $: image = images[imageShowIndex];

  const prevSlide = () => {
    if (imageShowIndex === 0){
      imageShowIndex = images.length-1;
    } else {
      imageShowIndex -= 1;
    }
    
  }

  const nextSlide = () => {
    if (imageShowIndex === images.length-1){
      imageShowIndex = 0;
    } else {
      imageShowIndex += 1;
    }
  }

  const gotToSlide = (idNumber) => imageShowIndex = idNumber;

  const showSlides = () => {
		nextSlide();
		setTimeout(showSlides, 8000);
	}
	
	onMount( () => {
			setTimeout(showSlides, 8000);
	} );



</script>

<style>

  .main {
    width: 100%;
    margin: 0%;
    padding: 0%;
    height: auto;
    align-items: center;
  }

  /* Position the image container (needed to position the left and right arrows) */
  .img-container {
    position: relative;
  }

  .thumbnails-row:after {
    content: "";
    display: table;
    clear: both;
  }

</style>

<div class="main" bind:clientWidth={w} bind:clientHeight={h}>
  <!-- Container for the image gallery -->
	{#await getImages}
  <p>Loading...</p>
	{:then images}
		<div class="img-container">

				<Slide image={image.imgUrl} 
							altTag={image.imgName} 
							attr={image.attribute} 
							slideNo={image.id +1}
							totalSlides={images.length}
              slideWidth={w}/>

		</div>

		<Caption caption={image.imgName} 
						on:prevClick={prevSlide} 
						on:nextClick={nextSlide}
            captionWidth={w}/>


  <div class="thumbnails-row" style="width:{w}px;">
    {#each images as {id, imgUrl, attribute}}
      <Thumbnail thumbImg={imgUrl} 
                altTag={attribute} 
                selected={id === imageShowIndex}
                on:click={() => gotToSlide(id)}
                thumbHeight={w/images.length}
                thumbWidth={w/images.length}/>
			{/each}

		</div>
    {:catch error}
      <p>{error.message}</p>
    {/await}
</div>

