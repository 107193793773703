<script>
    export let thumbImg;
    export let altTag;
    export let selected;
    export let thumbHeight;
    export let thumbWidth;
</script>

<style>

    /* Six columns side by side */
    .thumbnail-container {
    float: left;
    margin: 0%;
    padding: 0%;    
    }

    /* Add a transparency effect for thumnbail images */
    .sample {
    opacity: 0.4;
    }

    .sample:hover {
    opacity: 1;
    }

    .active {
    opacity: 1;
    }

</style>

<!-- Thumbnail images -->
<div class="thumbnail-container">
    <img class="sample cursor" 
         class:active={selected} 
         src="{thumbImg} " 
         style="height:{thumbHeight}px; width:{thumbWidth}px;"
         alt={altTag} on:click>
</div>