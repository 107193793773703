<script>
    export let image;
    export let altTag;
    export let attr;
    export let totalSlides;
    export let slideNo;
    export let slideWidth;
</script>

<style>

  /* Number text (1/3 etc) */
  .numbertext {
    color: black;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
  }

  img{
    width: 100%;
    height: auto;
    margin: 0%;
    padding: 0%;
  }



</style>

    <!-- Full-width images with number text -->
    <div class="mySlides" style="width:{slideWidth}px;">
    <div class="numbertext">{slideNo} / {totalSlides}</div>
        <img src={image} title={attr} alt={altTag}>
    </div>