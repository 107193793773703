 <script>
     import {createEventDispatcher} from "svelte";
     export let caption;
     export let captionWidth;

     const dispatch = createEventDispatcher();
 </script>
 
 <style>

  /* Next & previous buttons */
  .prev,
  .next {
    cursor: pointer;
    /* position: absolute; */
    /* top: 40%; */
    width: auto;
    padding: 10px;
    /* margin-top: -50px; */
    color: white;
    font-weight: bold;
    font-size: 20px;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
  }

  .prev, .next, a:link, a:hover, a:visited, a:visited{ text-decoration: none; }

  /* Position the "next button" to the right */
  .next {
    /* right: 0; */
    border-radius: 3px 0 0 3px;
  }

  /* On hover, add a black background color with a little bit see-through */
  .prev:hover,
  .next:hover {
    background-color: #00b053;
  }

  /* Container for image text */
  .caption-container {
    margin: 0%;
    width: 100%;
    height:auto;
    display: flex;
    align-items:center;
    padding: 5px 0 5px 0;
    justify-content: space-between;
    text-align: center;
    background-color: #00b053;
    /* padding: 2px 16px; */
    color: white;
  }

 </style>

  

  <!-- Image text and Next and previous buttons -->

  <div class="caption-container" style="width:{captionWidth}px;">
    <a href="#arrowL" class="prev" on:click={()=> dispatch('prevClick')}>&#10094;</a>
    <p id="caption">{caption}</p>
    <a href="#arrowR"  class="next" on:click={()=> dispatch('nextClick')}>&#10095;</a>
  </div>